import React, { useState, useContext, useEffect } from "react";
import {
  makeStyles,
  Container,
  CssBaseline,
  TextField,
  Button,
  Grid,
  Link,
  Typography,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Banner2 from "../../assets/banner/banner2.jpg";

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    backgroundImage: `url(${Banner2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.2,
    },
  },
  rightPanel: {
    flex: "0 0 40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      flex: "1 1 100%",
    },
  },
  paper: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[6],
    background: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "140%",
  },
  form: {
    width: "100%",
    maxWidth: "600px",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[3],
    backgroundColor: "#FFFAFA",
    padding: theme.spacing(4),
  },
  roundedInput: {
    borderRadius: theme.spacing(3),
    boxShadow: theme.shadows[1],
  },
  passwordIcon: {
    cursor: "pointer",
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  submit: {
    margin: theme.spacing(4, 0),
    borderRadius: theme.spacing(2),
    backgroundColor: "#343a40",
    color: "#fff",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#495057",
      color: "#000",
    },
  },
  forgotPassword: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(2),
    color: "#343a40",
    borderColor: "#343a40",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#495057",
      color: "#000",
    },
  },
  buttonShadow: {
    boxShadow: theme.shadows[3],
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    marginTop: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(4),
  },
  tab: {
    textTransform: "none",
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(0, 1),
  },
  tabText: {
    textTransform: "none",
    color: "#000",
  },
  tooltip: {
    fontSize: "14px",
  },
  whatsappButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(2),
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
    "&:hover": {
      background: "transparent",
    },
  },
  whatsappIcon: {
    fontSize: theme.spacing(4),
  },
  footer: {
    color: theme.palette.text.primary,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const { handleLogin } = useContext(AuthContext);
  const isDesktop = useMediaQuery("(min-width:600px)");

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Fun��o para trocar automaticamente de banner
    }, 10000); // 10 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs" className={classes.rightPanel}>
        <CssBaseline />
        <Paper elevation={6} className={classes.paper}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            className={classes.tabs}
          >
            <Tab
              label="Entrar"
              className={classes.tab}
              disableRipple
              classes={{ wrapper: classes.tabText }}
            />
            <Tab
              label="Criar conta"
              className={classes.tab}
              disableRipple
              classes={{ wrapper: classes.tabText }}
              onClick={() => {
                window.open(
                  "https://whats.multiatendimento.com.br/signup",
                );
              }}
            />
          </Tabs>
          {tabValue === 0 && (
            <>
              {/* <Typography component="h1" variant="h5" color="primary">
                Entrar
              </Typography> */
              }
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={user.email}
                  onChange={handleChangeInput}
                  autoComplete="email"
                  autoFocus
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.roundedInput,
                    },
                  }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Senha"
                  value={user.password}
                  onChange={handleChangeInput}
                  autoComplete="current-password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <span
                        className={classes.passwordIcon}
                        onClick={handleTogglePassword}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </span>
                    ),
                    classes: {
                      notchedOutline: classes.roundedInput,
                    },
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={`${classes.submit} ${classes.buttonShadow}`}
                >
                  Entrar
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  className={`${classes.forgotPassword} ${classes.buttonShadow}`}
                  onClick={() => {
                    window.open(
                      "https://whats.multiatendimento.com.br/forgetpsw",
                    );
                  }}
                >
                  Esqueci minha senha
                </Button>
              </form>
            </>
          )}
          {tabValue === 1 && (
            <>
              {/* Formul�rio para criar conta */}
              <Button
                fullWidth
                variant="contained"
                className={`${classes.submit} ${classes.buttonShadow}`}
                onClick={() => {
                  window.open(
                    "https://whats.multiatendimento.com.br/signup",
                  );
                }}
              >
                Criar Conta
              </Button>
            </>
          )}
          <Typography
            variant="body2"
            align="center"
            className={classes.footer}
          >
            Ao prosseguir, você concorda com nossos{" "}
            <Link
              href="https://app.whaticket.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Termos de Serviço
            </Link>{" "}
            e{" "}
            <Link
              href="https://app.whaticket.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Política de Privacidade
            </Link>
          </Typography>
        </Paper>
        <Tooltip
          title="Fale Conosco pelo WhatsApp"
          placement="left"
          arrow
          classes={{ tooltip: classes.tooltip }}
        >
          <a
            href="https://wa.me/5511976082505"
            className={classes.whatsappButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon className={classes.whatsappIcon} />
          </a>
        </Tooltip>
      </Container>
    </div>
  );
};

export default Login;
